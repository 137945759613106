import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    ReferenceArrayInput,
    ReferenceInput,
    TabbedForm,
    useNotify
} from 'react-admin';
import {CounterInput, MediaImageInput, RichTextInput} from 'components';
import {getUserFullName} from 'utils';
import {PinComponent, StatusComponent} from './components';

export default () => {
    const notify = useNotify();
    return (
        <Create
            mutationOptions={{
                onError: (error: any) => {
                    switch (error?.message) {
                        case 'PINNED_STORIES_LIMIT':
                            notify(
                                'Превышен лимит закрепленных историй (3 истории). Пожалуйста, открепите одну из историй',
                                {type: 'error'}
                            );
                            break;
                        case 'INCOMPATIBLE_STORY_STATUS':
                            notify('История должна быть в статусе "Опубликована"', {type: 'error'});
                            break;
                    }
                }
            }}>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <ReferenceArrayInput source='authorIds' reference='user'>
                        <AutocompleteArrayInput
                            label='Авторы'
                            filterToQuery={(searchText: any) => ({search: searchText})}
                            optionText={(record) =>
                                getUserFullName(record) + (record.jobPost?.name ? ` (${record.jobPost?.name})` : '')
                            }
                            fullWidth
                        />
                    </ReferenceArrayInput>
                    <MediaImageInput label='Изображение' source='file' />
                    <CounterInput label='Заголовок' source='title' fullWidth maxLength={150} />
                    <ReferenceInput source='departmentId' reference='department' sortBy='abbreviation'>
                        <AutocompleteInput
                            label='Подразделение'
                            fullWidth
                            optionText={(record) => record.abbreviation + ' – ' + record.fullName}
                            filterToQuery={(searchText: any) => ({abbreviation: searchText})}
                        />
                    </ReferenceInput>
                    <ReferenceArrayInput source='availableOrganizationIds' reference='organization'>
                        <AutocompleteArrayInput
                            label='Организации'
                            fullWidth
                            optionText='name'
                            filterToQuery={(searchText: any) => ({name: searchText})}
                        />
                    </ReferenceArrayInput>
                    <RichTextInput label='Описание' source='description' />
                    <StatusComponent />
                    <PinComponent />
                </TabbedForm.Tab>
                <TabbedForm.Tab label='Теги'>
                    <ReferenceArrayInput source='tagIds' reference='tag'>
                        <AutocompleteArrayInput label='Теги' optionText='name' fullWidth />
                    </ReferenceArrayInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
};
